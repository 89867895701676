<template>
    <div>
        <!-- /.modal -->
        <div class="modal fade" id="detailModal" tabindex="-1" role="dialog" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <!-- <div class="modal-header bg-primary">
                    <h5 class="modal-title" id="detailModalLabel"> Data Detail</h5>
                </div> -->
                <div class="modal-body">
                    <h6 class="text-bold">Informasi Transaksi</h6>
                     <div class="row">
                        <div class="col-sm-3">
                             Nomor Transaksi 
                        </div>
                        <div class="col-sm-1 text-right">
                            :
                        </div>
                        <div class="col-sm-6">
                            <label > dataDetail.transaction_code</label>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                             Id Vending 
                        </div>
                        <div class="col-sm-1 text-right">
                            :
                        </div>
                        <div class="col-sm-6">
                            <label > dataDetail.vending.vending_id</label>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                             Nama Vending 
                        </div>
                        <div class="col-sm-1 text-right">
                            :
                        </div>
                        <div class="col-sm-6">
                            <label  > dataDetail.vending.name</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                             Alamat Vending 
                        </div>
                        <div class="col-sm-1 text-right">
                            :
                        </div>
                         <div class="col-sm-6">
                            <label  > dataDetail.vending.address</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                             Tanggal Transaksi 
                        </div>
                        <div class="col-sm-1 text-right">
                            :
                        </div>
                        <div class="col-sm-6">
                            <label > </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                             Jumlah Produk 
                        </div>
                        <div class="col-sm-1 text-right">
                            :
                        </div>
                        <div class="col-sm-6">
                            <label > dataDetail.total_amount</label>
                        </div>
                    </div>
                    <div class="row">
                         <div class="col-sm-3">
                             Total Harga 
                        </div>
                        <div class="col-sm-1 text-right">
                            :
                        </div>
                        <div class="col-sm-6">
                            <label  > dataDetail.total_cost</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                             Status 
                        </div>
                        <div class="col-sm-1 text-right">
                            :
                        </div>
                        <div class="col-sm-6">
                            <label  > dataDetail.status</label>
                        </div>
                    </div>

                    <h6 class="title mt-2 text-bold"> Detail Produk </h6>
                </div>
                 <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                </div>
                </div>
            </div>
        </div>

        <!-- /.modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <form @submit.prevent="save" class="card">
                <div class="modal-content">
                <div class="modal-header bg-primary">
                   <h5 class="modal-title" id="exampleModalLabel"> <i class="fas fa-money-bill-wave" ></i>  Tarik saldo anda</h5>
                </div>
                <div class="modal-body">
                    <div class="box1 bg-light w-100">
                        <label> <i class="fas fa-info-circle text-primary" ></i> &nbsp; Saldo Tersedia </label>
                        <p> Lorem ipsum dolor sit amet consectetur. Nisi ultricies tincidunt egestas dignissim enim convallis. </P>
                    </div>   
                    <div class="col-sm-12 mt-4">
                        <div class="">
                             <p class="text-red" v-if="error"> {{errorText}} </p>
                            <label >Jumlah saldo yang di tarik</label>
                            <input type="number" class="form-control" id="recipient-name" v-model="form.amount" :disabled="loading">
                        </div>
                            <div class="form-group">
                            <p class="text-secondary">Saldo tersedia Rp {{formatPrice(saldo)}}</p>
                           
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                     <button type="submit" class="btn btn-primary" :disabled="loading">
                        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                        {{ loading ? 'Saving ...' : 'Save' }}
                    </button>
                </div>
                </div>
                </form>
            </div>
        </div>
      <div class="container-fluid">
            <div class="row mb-2">
                <!-- <div class="col-sm-4">
                    <div class="box1 m-2">
                       
                    </div>
                </div> -->
                <div class="col-sm-12">
                    <div class="box1 m-2 float-right">
                      
                        <div class="w-100 border-bottom">
                            <label> Saldo Tersedia  <i class="fas fa-info-circle text-primary" ></i> </label>
                            <button class="btn btn-white float-right text-primary" @click="showModal"> Tarik dana <i class="fas fa-chevron-right ml-3"></i> </button>
                        </div>
                        <h3> Rp. {{formatPrice(saldo)}} </h3>

                    </div>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
      
        <data-tables :config="config"  class="container-fluid" id="sampleTable"></data-tables>
                
    </div>
</template>


<script>
import DataTables from '@components/DataTables.vue'
export default {
     components: {
        DataTables
    },

    data() {
        return {
            active: false,
            errorDialog: null,
            errorText: '',
            uploadFieldName: 'file',
            error: false,
            loading: false,
            text_validation : null,
            maxSize: 1024,
            saldo:0,
            form : {
                amount:0,
            }, 
            disabled: false,
            
            config: {
                columns: [{
                    title: 'disbursement_code',
                    data: 'disbursement_code'
                }, {
                    title: 'amount',
                    data: 'amount'
                },{
                    title: 'status',
                    data: 'status'
                },{
                    title: 'Tanggal',
                    data: 'created',
                     render: (val) => {
                        return this.formatDate(val)
                    }
                    
                }],
                url:this.Api.base + '/disbrusmentsLog',
                order: [[0, "desc"]],
            }
        }
    },
    mounted() {
        this.getDataSaldo()
    },
    props: {
      value: Object,
    },
    methods: {
        add() {
        },
        clickRow(row) {
            $("#detailModal").modal("show");
        },
         showModal() {
          this.error=false
          $("#exampleModal").modal("show");
        },
        defaultBtnImage(){
            const defaultbtn = document.querySelector("#default-btn");
            defaultbtn.click();

        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            if(selectedImage.type === "image/png" || selectedImage.type === "image/jpeg") {
              if(selectedImage.size <= 1024 * 1024) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.form.image = e.target.result;
                }
                reader.readAsDataURL(selectedImage);
              } else {
                alert('File too big (> 1MB)');
              }
            } else {
              alert('File is not supported')
            }
        },
        save() {
            this.error = false
            this.loading = true
            this.errorText=""
            if(this.form.amount >= 400000 ){
                this.Api.post('/xendit/disbursements', this.form).then(res => {
                    this.loading = false
                    this.refresh();
                    $('#exampleModal').modal('hide');
                }).catch((e)  => {
                    this.loading = false
                    this.error = true
                    this.errorText = e.response.data.message
                })
            }else{
                this.error = true
                this.errorText="Minimal penarikan 400.000"
                this.loading= false
            }
        },
        async getDataSaldo(){
            this.Api.get(`/wallet/balance`).then(res =>{
                this.saldo = res.data
            })
            .catch((e) =>{

            })
        },
         formatDate(value) {
            return moment(String(value)).format('DD-MMM-YYYY HH:mm')
        },
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        refresh(){
            this.config = { 
                url: this.Api.base + '/disbrusmentsLog'
            }
        }     

    },
}
</script>
<style>
    .box1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 12px 24px;
        gap: 8px;
        width: 360px;
        height: 150px;
        left: 64px;
        top: 102px;
        background: #FFFFFF;
        border-radius: 12px; 
    }
    .label1{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: #0A0A0A;
    }
    .label2{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #404040;
    }
    .label3{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #404040;
    }
    .label4{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #32434A;

    }
    .balel5{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      color: #000000;
    }
    .borderDiv{
      border: 1px solid #C2C2C2;
    }
    .notActive{
        color : red;
    }
</style>
